<template>
    <div class="radio-group__items" :class="[vertical && 'vertical']">
      <div  v-for="(item, index) in items"
            class="radio-group__item"
            :key="'radio-group__item' + index" >
        <RadioGroup
            :data="item"
            :modelValue="modelValue"
            :disabled="disabled"
            @update:modelValue="$emit('update:modelValue', $event)"
        />

        <HelpTopic class="select__help-topic" :iframeData="item.help_topic_url" v-if="item.help_topic_url" />

      </div>
    </div>
</template>

<script>
import RadioGroup from '../form/RadioGroup.vue'
import HelpTopic from '../elements/HelpTopic.vue'

export default({
    props: ['items', 'modelValue', 'vertical', 'disabled'],
    components: {
        RadioGroup,
      HelpTopic
    },
    setup() {

    },
})

</script>


<style scoped lang="scss">
.radio-group {
    &__items {
        display: flex;
      flex-wrap: wrap;
    }
    &__item {

      display: flex;
      align-items: center;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
.vertical {
    &.radio-group__items {
        flex-direction: column;
    }
    .radio-group {
        &__item {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}
</style>
